import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Overview`}</h4>
    <p>{`TypeScript isn’t just a strongly-typed replacement for JavaScript – it’s a full language with lots of modern features, including a cutting-edge type system. This awesome type system is often overlooked in favor of other features such as decorators and async/await, but is one of the best reasons to use TypeScript.`}</p>
    <p>{`In this session, Spencer will discuss the advanced type features of TypeScript, including intersection types, type guards, nullable types, type aliases, string literals, and discriminated unions. We’ll also discuss ways to take full advantage of TypeScript’s compile-time checking to ensure your code is clean and safe.`}</p>
    <h5><a parentName="h5" {...{
        "href": "https://github.com/schneidenbach/TypeScriptTypeSystem"
      }}>{`Click to Download Slides + More`}</a></h5>
    <h4>{`Presented/Presenting At`}</h4>
    <p>{`Chicago Code Camp 2017`}<br parentName="p"></br>{`
`}{`Detroit.Code() 2017`}<br parentName="p"></br>{`
`}{`Kansas City Developer Conference 2017`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      